<template>
  <v-dialog
    scrollable
    persistent
    v-model="dialog"
    max-width="500px"
    transition="dialog-transition"
    :fullscreen="$vuetify.breakpoint.smAndDown"
  >
    <template #activator="{ on }">
      <v-btn
        small
        v-on="on"
        color="primary"
        class="text-none"
        :class="$vuetify.breakpoint.smAndDown ? '' : 'ml-4'"
      >
        <v-icon
          left
          small
          v-text="'$invite'"
        ></v-icon>
        {{ $t('admin.users.addUsers') }}
      </v-btn>
    </template>
    <v-form ref="form" v-model="isValid">
    <v-card>
      <v-card-title primary-title>
        <span>
          {{ $t('admin.users.addUsers') }}
        </span>
        <v-spacer></v-spacer>
        <v-btn icon @click="closeDialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-text-field
          single-line
          dense
          style="width: 90%;margin-left:2%;margin-top:1%"
          :disabled="saving"
          label="First name*"
          v-model="user.firstname"
          :rules="nameRules"
        ></v-text-field>
        <v-text-field
          single-line
          style="width: 90%;margin-left:2%;margin-top:2%"
          dense
          label="Last name"
          :disabled="saving"
          v-model="user.lastname"
        ></v-text-field>
        <template>
          <v-text-field
            single-line
            style="width: 90%;margin-left:2%;margin-top:2%"
            dense
            label="Password*"
            :disabled="saving"
            v-model="user.password"
          ></v-text-field>
        </template>
        <v-text-field
          single-line
          style="width: 90%;margin-left:2%;margin-top:2%"
          dense
          :disabled="saving"
          label="Email Id*"
          v-model="user.emailId"
          :rules="emailRules"
        ></v-text-field>
        <v-text-field
          single-line
          style="width: 90%;margin-left:2%;margin-top:2%"
          dense
          :disabled="saving"
          v-model="user.phoneNumber"
          label="Phone Number*"
          hide-details="auto"
          :rules="phoneRules"
        ></v-text-field>
        <div class="caption">
          <sup>#</sup> Please input atleast one of email ID or phone number
        </div>
        <v-select
          single-line
          style="width: 90%;margin-left:2%;margin-top:3%"
          dense
          :items="roles"
          label="Role*"
          item-text="roleDescription"
          item-value="roleId"
          :disabled="saving"
          v-model="user.roleId"
          :rules="roleRules"
        ></v-select>
        </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          type="submit"
          color="primary"
          id="inviteUsers"
          class="text-none mb-2 mr-3"
          @click="onSubmit('saveandexit')"
          :loading="loading"
          :disabled="!isValid"
        >
          {{ $t('admin.users.saveandexit') }}
        </v-btn>
        <v-btn
          type="submit"
          color="primary"
          class="text-none mb-2 mr-3"
          @click="onSubmit('saveandadd')"
          :loading="loading"
          :disabled="!isValid"
        >
          {{ $t('admin.users.saveandadd') }}
        </v-btn>
      </v-card-actions>
    </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
// import IdentifierInput from '@/components/auth/IdentifierInput.vue';

export default {
  name: 'AddUsers',
  data() {
    return {
      dialog: false,
      loading: false,
      user: {},
      // invitedUsers: [],
      isValid: false,
      saving: false,
      nameRules: [
        (v) => !!(v && v.trim()) || 'First name is required.',
      ],
      roleRules: [
        (v) => !!v || 'Role is required.',
      ],
      emailRules: [
        (v) => (!v || /.+@.+\..+/.test(v)) || 'Invalid Email ID.',
      ],
      phoneRules: [
        (v) => (!v || /^[4-9][0-9]{9}$/.test(v)) || 'Invalid Phone number.',
      ],
    };
  },
  computed: {
    ...mapState('user', ['activeSite', 'roles']),
    ...mapState('admin', ['users']),
  },
  methods: {
    ...mapActions('user', ['addUser']),
    ...mapMutations('helper', ['setAlert']),
    async onSubmit(actionname) {
      this.loading = true;
      const addPayload = {
        firstname: this.user.firstname,
        lastname: this.user.lastname,
        roleId: this.user.roleId,
        emailId: this.user.emailId,
        phoneNumber: `91${this.user.phoneNumber}`,
        userState: 'ACTIVE',
        password: this.user.password,
        siteIds: [this.activeSite],
      };
      const payloadResponse = await this.addUser(addPayload);
      // console.log('payloadResponse', payloadResponse);
      const responseData = Object.values(payloadResponse)[0];
      if (responseData.message) {
        this.setAlert({
          show: true,
          type: 'success',
          message: `${responseData.message}`,
        });
        if (actionname === 'saveandexit') {
          this.dialog = false;
        }
        this.user = {};
        this.$refs.form.reset();
        this.$emit('added');
      } else {
        this.setAlert({
          show: true,
          type: 'error',
          message: `${responseData.errorMessage}`,
        });
      }
      this.loading = false;
    },
    closeDialog() {
      this.dialog = false;
      this.user = {};
      this.$refs.form.reset();
    },
  },
};
</script>
